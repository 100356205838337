import { useSelector } from "react-redux";
import {
  getDataFromLocalStorage,
  getStringFromLocalStorage,
} from "./localStore";

type Item = {
  deal_code?: string;
  service_method?: { [key: string]: boolean };
  is_sold: boolean;
  stores?: { store_code: string }[];
  week_days?: { [key: string]: boolean };
};

const useCart = () => {
  const service_method = getStringFromLocalStorage("serviceMethod") as any;
  const cart_slicer = useSelector((state: any) => state.cart);
  const discounted_offer = getDataFromLocalStorage("discounted_offer");
  const promo_code = getDataFromLocalStorage("promo_code");
  const store = getDataFromLocalStorage("store");

  const get_total_quantity = cart_slicer.cart?.reduce(
    (accumulator: any, item: any) => accumulator + item?.quantity,
    0
  );

  const total_promo_products_quantity = cart_slicer.cart
    ?.filter((itemx: any) => itemx?.promo_applied)
    ?.reduce((accumulator: any, item: any) => accumulator + item?.quantity, 0);

  const available_items =
    cart_slicer.cart?.length === 0
      ? []
      : cart_slicer.cart?.filter((item: any) => {
          return (
            item?.service_method?.[service_method?.toLowerCase()] &&
            item?.is_sold &&
            item?.stores?.some(
              (itemx: any) => itemx?.store_code === store?.store_code
            )
          );
        });

  const total_price = () => {
    let total;

    total = available_items?.reduce(
      (sum: any, item: any) => sum + item?.price * item?.quantity,
      0
    );
    return total?.toFixed(2);
  };

  const get_total_price = total_price();

  const get_discounted_price = available_items
    ?.reduce((total: number, item: any) => {
      let sub_total = 0;

      if (discounted_offer && item?.category_code !== "offers") {
        sub_total =
          Number(item?.price) * item?.quantity -
          (discounted_offer?.deal_items?.[0]?.discount_type === "fixed_price"
            ? Number(discounted_offer?.deal_items?.[0]?.discount_value) *
              item?.quantity
            : discounted_offer?.deal_items?.[0]?.discount_type === "dollar_off"
            ? Number(item?.price) * item?.quantity -
              Number(discounted_offer?.deal_items?.[0]?.discount_value)
            : discounted_offer?.deal_items?.[0]?.discount_type === "percent_off"
            ? ((Number(item?.price) * item?.quantity) / 100) *
              discounted_offer?.deal_items?.[0]?.discount_value
            : 0);
      } else {
        sub_total = item?.price * item?.quantity;
      }

      return total + sub_total;
    }, 0)
    .toFixed(2);

  const get_discounted_deal_actual_price = available_items
    ?.reduce((total: number, item: any) => {
      const sub_total =
        item?.sub_category_code !== "half_half" &&
        item?.category_code !== "offers"
          ? item?.price * item?.quantity
          : 0;
      return total + sub_total;
    }, 0)
    .toFixed(2);

  const get_discounted_deal_price = available_items
    ?.reduce((total: number, item: any) => {
      let sub_total = 0;

      if (
        discounted_offer &&
        item?.category_code !== "offers" &&
        item?.sub_category_code !== "half_half"
      ) {
        sub_total =
          Number(item?.price) * item?.quantity -
          (discounted_offer?.deal_items?.[0]?.discount_type === "fixed_price"
            ? Number(discounted_offer?.deal_items?.[0]?.discount_value) *
              item?.quantity
            : discounted_offer?.deal_items?.[0]?.discount_type === "dollar_off"
            ? Number(item?.price) * item?.quantity -
              Number(discounted_offer?.deal_items?.[0]?.discount_value)
            : discounted_offer?.deal_items?.[0]?.discount_type === "percent_off"
            ? ((Number(item?.price) * item?.quantity) / 100) *
              discounted_offer?.deal_items?.[0]?.discount_value
            : 0);
      } else {
        sub_total = 0;
      }

      return total + sub_total;
    }, 0)
    .toFixed(2);

  const is_product_discount_applied = cart_slicer.cart?.some((item: any) => {
    return item?.discount_applied;
  });

  const get_product_quantity = (id: number) => {
    return cart_slicer.cart?.find((item: any) => item.id === id)?.quantity || 0;
  };

  const get_unavailable_cart_items = () => {
    let unavailable_items = [] as any;
    cart_slicer.cart?.some((item: any) => {
      if (
        !item?.service_method?.[service_method?.toLowerCase()] ||
        !item?.is_sold ||
        !item?.stores?.some(
          (itemx: any) => itemx?.store_code === store?.store_code
        )
      ) {
        unavailable_items.push(item);
      }
      return false;
    });
    return unavailable_items;
  };

  const product_available = (item: Item): boolean => {
    const is_service_available =
      item.service_method?.[service_method.toLowerCase()] ?? false;
    const is_sold = item.is_sold;
    const is_store_valid =
      item.stores?.some(
        (storeItem) => storeItem.store_code === store?.store_code
      ) ?? false;

    return is_service_available && is_sold && is_store_valid;
  };

  const product_valid_for_promo = (item: any) => {
    return !item?.promo_applied || !product_available(item)
      ? false
      : promo_code?.products?.some((itemx: any) => {
          if (item?.category_code === "pizza") {
            return (
              item?.osg_code === itemx?.osg_code &&
              promo_code?.product_pizza_sizes?.includes(
                item?.products?.[0]?.size_code
              ) &&
              promo_code?.product_sub_category?.includes(
                item?.sub_category_code
              )
            );
          } else if (item?.category_code === "drinks") {
            return (
              promo_code?.product_other_sizes?.includes(
                item?.products?.[0]?.size_code
              ) && item?.product_code === itemx?.product_code
            );
          } else {
            return item?.product_code === itemx?.product_code;
          }
        });
  };

  const item_cart_price = (item: any): number => {
    try {
      let total = item?.price || item?.product_price || 0;

      let discount_type: string | undefined;
      let discount_value: number | undefined;

      if (promo_code) {
        ({ discount_type, discount_value } = promo_code);
      } else if (discounted_offer) {
        const deal_item = discounted_offer?.deal_items?.[0];
        ({ discount_type, discount_value } = deal_item || {});
      } else if (
        item?.discount_applied ||
        (item?.is_discount_available && !promo_code)
      ) {
        discount_type = item?.product_discount_type;
        discount_value = item?.product_discount;
      }

      const is_valid_promo =
        promo_code?.promo_type === "product_specific" &&
        product_valid_for_promo(item);
      const is_eligible_for_discount =
        item?.is_discount_available ||
        (item?.discount_applied && !promo_code) ||
        (discounted_offer && item?.category_code !== "offers") ||
        is_valid_promo;

      if (
        is_eligible_for_discount &&
        discount_type &&
        discount_value !== undefined
      ) {
        switch (discount_type) {
          case "fixed_price":
            total = discount_value;
            break;
          case "dollar_off":
            total -= discount_value;
            break;
          case "percent_off":
            total -= total * (discount_value / 100);
            break;
        }
      }

      return Math.max(Number(total), 0);
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const promo_price_before_discount = () => {
    let total;

    if (
      promo_code?.allowed_other_coupon &&
      !promo_code?.allow_coupons_discounted_price
    ) {
      total = available_items.reduce(
        (sum: any, item: any) =>
          sum +
          (item?.category_code === "offers"
            ? item?.actual_price
            : item?.price) *
            item?.quantity,
        0
      );
    } else {
      total = available_items.reduce(
        (sum: any, item: any) => sum + item?.price * item?.quantity,
        0
      );
    }
    return total?.toFixed(2);
  };

  const get_promo_price = () => {
    try {
      let total;
      if (promo_code?.promo_type === "product_specific") {
        if (
          promo_code?.allowed_other_coupon &&
          promo_code?.allow_coupons_discounted_price
        ) {
          total = available_items?.reduce((sum: any, item: any) => {
            return (
              sum +
              (item?.promo_applied ? item_cart_price(item) : item?.price) *
                item?.quantity
            );
          }, 0);
        } else {
          total = available_items?.reduce((sum: any, item: any) => {
            return (
              sum +
              Number(
                (item?.promo_applied ? item_cart_price(item) : item?.price) *
                  item?.quantity
              )
            );
          }, 0);
        }
      } else {
        total = get_total_price;
      }

      if (promo_code?.promo_type === "entire_order") {
        const { discount_type, discount_value } = promo_code;

        switch (discount_type) {
          case "fixed_price":
            total = discount_value;
            break;

          case "dollar_off":
            total = get_total_price - discount_value;
            break;

          case "percent_off":
            total = get_total_price - get_total_price * (discount_value / 100);
            break;

          default:
            total = get_total_price;
        }
      }

      return Number(Math.max(total, 0))?.toFixed(2);
    } catch (error) {
      console.log(error);
    }
  };

  const unavailable_promo_products = () => {
    const promo_products = promo_code?.products?.map((item: any) => {
      return item?.osg_code || item?.product_code;
    });

    if (promo_code?.promo_type === "entire_order") {
      return cart_slicer.cart
        ?.map((item: any) => {
          const filteredProducts = item?.products?.filter((product: any) => {
            if (item?.category_code === "pizza") {
              return (
                promo_products?.includes(product?.osg_code) &&
                promo_code?.product_pizza_sizes?.includes(product?.size_code) &&
                promo_code?.product_sub_category?.includes(
                  product?.sub_category_code
                )
              );
            } else if (item?.category_code === "drinks") {
              return (
                promo_code?.product_other_sizes?.includes(product?.size_code) &&
                promo_products?.includes(product?.product_code)
              );
            } else {
              return promo_products?.includes(product?.product_code);
            }
          });

          if (filteredProducts.length > 0) {
            if (item?.sub_category_code === "half_half") {
              return { ...filteredProducts[0] };
            } else {
              return {
                ...filteredProducts[0],
                en_content: item?.en_content,
                ge_content: item?.ge_content,
              };
            }
          }

          return null;
        })
        .filter(Boolean)[0];
    } else {
      return null;
    }
  };

  const is_deal_in_cart = cart_slicer.cart?.some(
    (item: any) => item?.category_code === "offers"
  );

  const is_coupon_applied = promo_code || discounted_offer;

  const get_product_discounted_total_price = () => {
    let total;

    total = available_items?.reduce(
      (sum: any, item: any) =>
        sum +
        (item?.discount_applied ? item_cart_price(item) : item?.price) *
          item?.quantity,
      0
    );
    return total?.toFixed(2);
  };
  const product_discounted_total_price = get_product_discounted_total_price();
  const not_allowed_product_promo = unavailable_promo_products() as any;
  const promo_price = get_promo_price();

  const get_discount_value = () => {
    let discount = 0;
    if (promo_code) {
      discount = Number(promo_price_before_discount()) - Number(promo_price);
    } else if (discounted_offer) {
      discount = get_total_price - get_discounted_price;
    } else if (is_product_discount_applied) {
      discount = get_total_price - product_discounted_total_price;
    }
    return Number(Math.max(discount, 0))?.toFixed(2);
  };

  const pizza_size_price = (item: any, size: string) => {
    try {
      let price = item?.product_sizes?.find((itemx: any) => {
        return itemx?.size_code?.toLowerCase() === size?.toLowerCase();
      })?.product_price;
      return Number(price)?.toFixed(2);
    } catch (error) {
      console.log(error);
    }
  };

  const discount_value = get_discount_value();

  const get_subtotal = () => {
    let sub_total = 0 as any;

    if (promo_code && Number(discount_value) > 0) {
      sub_total = promo_price;
    } else if (discounted_offer) {
      sub_total = get_discounted_price;
    } else if (is_product_discount_applied) {
      sub_total = product_discounted_total_price;
    } else {
      sub_total = get_total_price;
    }
    return Number(Math.max(sub_total, 0))?.toFixed(2);
  };

  const sub_total = get_subtotal();

  const promo_valid = !promo_code
    ? false
    : !(
        Number(promo_price_before_discount()) >
          Number(promo_code?.max_order_price) ||
        Number(get_total_price) < Number(promo_code?.min_order_price) ||
        (is_deal_in_cart && !promo_code?.allowed_other_coupon) ||
        (not_allowed_product_promo &&
          promo_code?.promo_type === "entire_order") ||
        (promo_code?.promo_type === "product_specific" &&
          !promo_code?.allowed_max_qty &&
          total_promo_products_quantity > promo_code?.max_product_qty)
      );

  return {
    get_total_quantity,
    get_total_price,
    get_discounted_price,
    get_discounted_deal_actual_price,
    get_discounted_deal_price,
    promo_price,
    promo_valid,
    get_product_quantity,
    get_unavailable_cart_items,
    product_available,
    item_cart_price,
    product_valid_for_promo,
    total_promo_products_quantity,
    not_allowed_product_promo,
    promo_price_before_discount,
    is_deal_in_cart,
    discount_value,
    is_product_discount_applied,
    product_discounted_total_price,
    sub_total,
    is_coupon_applied,
    pizza_size_price,
  };
};

export default useCart;
