import styled from "@emotion/styled";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  Avatar,
  Badge,
  Divider,
  ListItem,
  ListItemIcon,
  Stack,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { UserDefault } from "assets/svg";
import classNames from "classnames";
import { useUserSettings } from "hooks/useUserSettings";
import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiServices from "services/RequestHandler";
import { RootState } from "store";
import { language_en, language_ge } from "store/transalationReducer";
import { set_store } from "store/userAddress";
import { handle_clear_form, update_language } from "store/userProfile";
import { set_forgot_password } from "store/userRegisterReducer";
import { global_transalations, header_transalation } from "transalations";
import Button from "ui-components/Button";
import LoadingComponent from "ui-components/LoadingComponent";
import { set_localize_content } from "utils/helper";
import {
  clearDataFromLocalStorage,
  saveDataToLocalStorage,
} from "utils/localStore";
import empty_cart from "../../../../../src/assets/images/global/empty_cart.png";
import store_icon from "../../../../../src/assets/images/header/store.png";
import user_default from "../../../../../src/assets/images/header/user_default.jpg";
import offers_default_image from "../../../../assets/images/Offer/offer_global.png";
import arrow_up from "../../../../assets/images/header/arrow_up.png";
import cart_active from "../../../../assets/images/header/cart_active.png";
import flag_en from "../../../../assets/images/header/flag_en.png";
import flag_ge from "../../../../assets/images/header/flag_ge.png";
import shopping_cart from "../../../../assets/images/header/shopping_cart.png";
import store_active from "../../../../assets/images/header/store-active.png";
import track from "../../../../assets/images/header/track.png";
import track_active from "../../../../assets/images/header/track_active.png";
import useCart from "../../../../utils/cart";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#000000",
    boxShadow: "0px 0px 0px 1px lightgrey",
    padding: 0,
    top: "-4px",
    borderRadius: 8,
    right: -18,
    position: "relative",
  },
}));

export default function HeaderIcons({ classes = {} }: { classes?: any }) {
  const cart_slicer = useSelector((state: RootState) => state.cart) as any;
  const {
    language,
    token,
    location,
    store,
    discounted_offer,
    user,
    dispatch,
    promo_code,
  } = useUserSettings();

  const {
    get_total_quantity,
    product_available,
    item_cart_price,
    product_valid_for_promo,
    sub_total,
  } = useCart();

  const navigate = useNavigate();
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      loader: false,
    }
  );

  const handle_logout = async () => {
    if (!token) return;

    setState({ loader: true });

    try {
      await apiServices.postFromApi("customers/logout", {}, "customer");

      clearDataFromLocalStorage("token");
      clearDataFromLocalStorage("membership");
      clearDataFromLocalStorage("store");
      clearDataFromLocalStorage("selected_address");
      clearDataFromLocalStorage("serviceMethod");
      clearDataFromLocalStorage("user");
      clearDataFromLocalStorage("favorites");
      clearDataFromLocalStorage("cart");
      clearDataFromLocalStorage("discounted_offer");
      clearDataFromLocalStorage("language");
      clearDataFromLocalStorage("session_expired");
      clearDataFromLocalStorage("promo_code");
      dispatch(set_forgot_password(false));
      navigate("/welcome", { replace: true });
      window.location.reload();

      toast.success(
        set_localize_content(header_transalation.logout_success, language)
      );
    } catch (error) {
      console.log(error);
    }

    setState({ loader: false });
  };

  useEffect(() => {
    dispatch(set_store(store));
    // eslint-disable-next-line
  }, []);

  const update_my_profile = (lng: string) => {
    if (!token) return;
    try {
      const data = { ...user, language: lng };

      dispatch(
        update_language({
          end_point: `/customers/add-profile`,
          body: data,
        })
      );
      saveDataToLocalStorage("user", data);
      dispatch(handle_clear_form(null as any));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <div className="header-icons">
        <Link to={"/stores"} state={{ change_service: true }}>
          <div>
            <img
              src={location.pathname === "/stores" ? store_active : store_icon}
              alt="Dominos Stores"
            />
            <p
              style={{
                color: location.pathname === "/stores" ? "#E80045" : "black",
                fontWeight: language === "en" ? "" : "bold",
              }}
            >
              {set_localize_content(header_transalation.stores, language)}
            </p>
          </div>
        </Link>

        {store ? (
          <Link to={"/tracking"}>
            <div>
              <img
                src={
                  location.pathname === "/tracking" ||
                  location.pathname.startsWith("/track-my-order")
                    ? track_active
                    : track
                }
                alt="Track"
              />
              <p
                style={{
                  color:
                    location.pathname === "/tracking" ||
                    location.pathname.startsWith("/track-my-order")
                      ? "#E80045"
                      : "black",
                  fontWeight: language === "en" ? "" : "bold",
                }}
              >
                {set_localize_content(header_transalation.tracker, language)}
              </p>
            </div>
          </Link>
        ) : null}

        <div>
          <BootstrapTooltip
            PopperProps={{ style: { zIndex: 99999 } }}
            title={
              language === "en" ? (
                <div
                  className="header-tooltip"
                  onClick={() => {
                    dispatch(language_ge());
                    update_my_profile("ge");
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: -16,
                      right: 28,
                    }}
                  >
                    <img src={arrow_up} alt="Arrow Up" loading="lazy" />
                  </div>
                  <img src={flag_ge} alt="Flag" style={{ width: 28 }} />
                  <p style={{ fontWeight: 600 }}>
                    {set_localize_content(header_transalation.ge, language)}
                  </p>
                </div>
              ) : (
                <div
                  className="header-tooltip"
                  onClick={() => {
                    dispatch(language_en());
                    update_my_profile("en");
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: -16,
                      right: 28,
                    }}
                  >
                    <img src={arrow_up} alt="Arrow Up" loading="lazy" />
                  </div>
                  <img src={flag_en} alt="Flag" />
                  <p style={{ fontWeight: "600" }}>
                    {set_localize_content(header_transalation.eng, language)}
                  </p>
                </div>
              )
            }
            placement="bottom-end"
          >
            {language === "en" ? (
              <div>
                <img src={flag_en} alt="Flag" />
                <p style={{ fontWeight: language === "en" ? "" : "bold" }}>
                  {set_localize_content(header_transalation.eng, language)}
                </p>
              </div>
            ) : (
              <div>
                <img src={flag_ge} alt="Flag" />
                <p style={{ fontWeight: language === "en" ? "" : "bold" }}>
                  {set_localize_content(header_transalation.ge, language)}
                </p>
              </div>
            )}
          </BootstrapTooltip>
        </div>

        {token && store ? (
          <BootstrapTooltip
            PopperProps={{ style: { zIndex: 99999 } }}
            placement="bottom-end"
            title={
              <div className="user-tooltip">
                <div style={{ position: "absolute", top: -16, right: 28 }}>
                  <img src={arrow_up} alt="Arrow Up" loading="lazy" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 14,
                  }}
                >
                  <li className="list_item_text">
                    <div style={{ marginRight: 12, paddingLeft: 8 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          width: 70,
                          height: 70,
                          overflow: "hidden",
                        }}
                      >
                        {user?.profile_pic ? (
                          <img
                            src={user?.profile_pic || user_default}
                            alt="User"
                            style={{
                              objectFit: "cover",
                              marginBottom: 6,
                            }}
                            loading="lazy"
                            width={"100%"}
                            height={"100%"}
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.src = user_default;
                            }}
                          />
                        ) : (
                          <Avatar
                            style={{
                              width: 70,
                              height: 70,
                              color: "#000",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            {`${user?.first_name?.[0] ?? ""}${
                              user?.last_name?.[0] ?? ""
                            }`}
                          </Avatar>
                        )}
                      </div>
                    </div>
                    <p
                      className="IBM-Regular fs-16 text-black"
                      style={{ fontWeight: language === "en" ? "" : "bold" }}
                    >
                      {user?.first_name ??
                        set_localize_content(
                          header_transalation.user,
                          language
                        )}
                    </p>
                  </li>
                  <Divider />
                  <Link to={"/user-profile"}>
                    <ListItem
                      disablePadding
                      className={
                        location.pathname === "/user-profile"
                          ? "header_list_item"
                          : ""
                      }
                    >
                      <ListItemIcon
                        className="list_item_icon"
                        style={{ paddingLeft: 14 }}
                      >
                        <AccountCircleOutlinedIcon />
                      </ListItemIcon>
                      <span className="list_item_text">
                        {set_localize_content(
                          header_transalation.profile,
                          language
                        )}
                      </span>
                    </ListItem>
                  </Link>
                </div>
                <Divider />
                <Link to={"/order-history"}>
                  <ListItem
                    disablePadding
                    className={
                      location.pathname === "/order-history"
                        ? "header_list_item"
                        : ""
                    }
                  >
                    <ListItemIcon
                      className="list_item_icon"
                      style={{ paddingLeft: 14 }}
                    >
                      <DescriptionOutlinedIcon />
                    </ListItemIcon>
                    <span className="list_item_text">
                      {set_localize_content(
                        header_transalation.order_history,
                        language
                      )}
                    </span>
                  </ListItem>
                </Link>
                <Divider />
                <Link to={"/favorites"}>
                  <ListItem
                    disablePadding
                    className={
                      location.pathname === "/favorites"
                        ? "header_list_item"
                        : ""
                    }
                  >
                    <ListItemIcon
                      className="list_item_icon"
                      style={{ paddingLeft: 14 }}
                    >
                      <FavoriteBorderOutlinedIcon />
                    </ListItemIcon>
                    <span className="list_item_text">
                      {set_localize_content(
                        header_transalation.favorites,
                        language
                      )}
                    </span>
                  </ListItem>
                </Link>
                <Divider />
                {/* <Link to={"/user-points"}>
                  <ListItem
                    disablePadding
                    className={
                      location.pathname === "/user-points"
                        ? "header_list_item"
                        : ""
                    }
                  >
                    <ListItemIcon
                      className="list_item_icon"
                      style={{ paddingLeft: 14 }}
                    >
                      <RedeemOutlinedIcon />
                    </ListItemIcon>
                    <span className="list_item_text">
                      {set_localize_content(
                        header_transalation.rewards,
                        language
                      )}
                    </span>
                  </ListItem>
                </Link> */}
                <Divider />
                <ListItem onClick={handle_logout} disablePadding>
                  <ListItemIcon
                    className="list_item_icon"
                    style={{ paddingLeft: 14 }}
                  >
                    <LogoutOutlinedIcon />
                  </ListItemIcon>
                  <span className="list_item_text">
                    {set_localize_content(header_transalation.logout, language)}
                  </span>
                </ListItem>
              </div>
            }
          >
            <div>
              {user?.profile_pic ? (
                <img
                  src={user?.profile_pic}
                  width={30}
                  height={30}
                  style={{ objectFit: "cover", borderRadius: 100 }}
                  alt="User"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = user_default;
                  }}
                />
              ) : (
                <Avatar
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: 12,
                    textTransform: "uppercase",
                  }}
                >
                  {`${user?.first_name?.[0] ?? ""}${
                    user?.last_name?.[0] ?? ""
                  }`}
                </Avatar>
              )}
              <p style={{ fontWeight: language === "en" ? "" : "bold" }}>
                {user?.first_name ??
                  set_localize_content(header_transalation.user, language)}
              </p>
            </div>
          </BootstrapTooltip>
        ) : (
          store && (
            <div
              onClick={() => {
                clearDataFromLocalStorage("membership");
                clearDataFromLocalStorage("serviceMethod");
                clearDataFromLocalStorage("store");
                clearDataFromLocalStorage("selected_address");
                dispatch(set_forgot_password(false));
                clearDataFromLocalStorage("session_expired");
                clearDataFromLocalStorage("promo_code");
                navigate("/welcome", { replace: true });
                window.location.reload();
              }}
              className="pointer"
            >
              <UserDefault />
              <p style={{ fontWeight: language === "en" ? "" : "bold" }}>
                {set_localize_content(header_transalation.login, language)}
              </p>
            </div>
          )
        )}

        {store ? (
          <BootstrapTooltip
            placement="bottom-end"
            PopperProps={{ style: { zIndex: 99999 } }}
            title={
              <div>
                <div style={{ position: "absolute", top: -16, right: 28 }}>
                  <img src={arrow_up} alt="Arrow Up" />
                </div>
                <div style={{ height: 230, overflow: "auto" }}>
                  {cart_slicer?.cart.length > 0 ? (
                    cart_slicer?.cart?.map((item: any, index: number) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 5fr",
                            gap: ".5rem",
                            padding: 6,
                          }}
                        >
                          <div
                            className={"cart_product_image"}
                            style={{ position: "relative" }}
                          >
                            {!product_available(item) && (
                              <div
                                style={{
                                  position: "absolute",
                                  inset: 0,
                                  background: "#0000007d",
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  className=" Kanit-Medium uppercase text-white not-available-text-shadow"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "9px",
                                  }}
                                >
                                  {set_localize_content(
                                    global_transalations.not_available,
                                    language
                                  )}
                                </p>
                              </div>
                            )}
                            <img
                              style={{ objectFit: "contain" }}
                              src={
                                item?.category_code === "offers"
                                  ? item?.[language + "_content"]
                                      ?.web_media_global
                                    ? item?.[language + "_content"]
                                        ?.web_media_global
                                    : offers_default_image
                                  : item?.[language + "_content"]
                                      ?.web_media_global
                              }
                              alt={
                                item?.category_code === "offers"
                                  ? item?.[language + "_content"]?.web
                                  : item?.product_name?.[language]
                              }
                              loading="lazy"
                              width={68}
                              height={68}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: ".5rem",
                              }}
                            >
                              <p
                                className="Kanit-Regular fs-14 text-black"
                                style={{
                                  fontWeight: language === "en" ? "" : "bold",
                                }}
                              >
                                {item?.category_code === "offers"
                                  ? item?.[language + "_content"]?.web
                                  : item?.[language + "_content"]?.web ||
                                    item?.product_name?.[language]}
                              </p>

                              {item?.category_code === "offers" ? (
                                <div>
                                  <div>
                                    <s className="text-gray Kanit-regular">
                                      {(
                                        Number(
                                          item?.actual_price
                                        ) * Number(item?.quantity)
                                      ).toFixed(2) + "₾"}
                                    </s>
                                  </div>
                                  <div>
                                    {" "}
                                    <p className="Kanit-SemiBold fs-14">
                                      <span className="text-red">
                                        {(
                                          Number(item?.price) *
                                          Number(item?.quantity)
                                        ).toFixed(2) + "₾"}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              ) : (item?.category_code !== "offers" &&
                                  discounted_offer) ||
                                item?.discount_applied ||
                                (promo_code?.promo_type ===
                                  "product_specific" &&
                                  product_valid_for_promo(item)) ? (
                                <p className="Kanit-SemiBold fs-14">
                                  <s className="text-gray">
                                    {(
                                      Number(
                                        item?.actual_price ?? item?.price
                                      ) * Number(item?.quantity)
                                    ).toFixed(2)}
                                  </s>
                                  <br />
                                  <span className="text-red">
                                    {(
                                      item_cart_price(item) *
                                      Number(item?.quantity)
                                    )?.toFixed(2)}
                                    ₾
                                  </span>
                                </p>
                              ) : (
                                <p className="Kanit-SemiBold fs-14 text-red">
                                  {(
                                    item_cart_price(item) *
                                    Number(item?.quantity)
                                  )?.toFixed(2)}
                                  ₾
                                </p>
                              )}
                            </div>
                            <div className="cart-decs">
                              <span
                                className="Kanit-Light capitalize"
                                style={{ fontSize: 12 }}
                              >
                                {item?.category_code === "pizza"
                                  ? `${set_localize_content(
                                      global_transalations?.[
                                        item?.products?.[0]?.size_code
                                      ],
                                      language
                                    )},   
                                  ${
                                    item?.products?.[0]?.dough?.dough_desc?.[
                                      language
                                    ]
                                  },
                                  ${
                                    item?.products?.[0]?.edge?.dough_desc?.[
                                      language
                                    ]
                                  }`
                                  : item?.[`${language}_content`]?.web_desc}
                              </span>
                            </div>
                          </div>
                        </div>
                        <Divider />
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: 300,
                      }}
                    >
                      <img
                        src={empty_cart}
                        width={150}
                        style={{ filter: "grayscale(100%)" }}
                        alt="empty cart"
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 4,
                    padding: 12,
                    marginTop: 0,
                  }}
                >
                  <Button
                    onClick={() => navigate("/cart")}
                    value={set_localize_content(
                      header_transalation.view_cart,
                      language
                    )}
                    classesNames={classNames(
                      classes.red_rounded_button,
                      "Kanit-Regular fs-12"
                    )}
                    style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  />
                  <p
                    className="Kanit-Regular fs-16"
                    style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    {set_localize_content(header_transalation.total, language)}:
                    <span className="text-red"> {sub_total}₾</span>
                  </p>
                </div>
              </div>
            }
          >
            <Stack spacing={1} direction="row" sx={{ position: "relative" }}>
              <Badge
                badgeContent={get_total_quantity}
                color="error"
                sx={{
                  "& .MuiBadge-badge": {
                    top: 2,
                    right: language === "en" ? 0 : 10,
                  },
                }}
              >
                <div
                  onClick={() => navigate("/cart")}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={
                      location.pathname === "/cart"
                        ? cart_active
                        : shopping_cart
                    }
                    alt="Cart"
                    style={{ display: "block" }}
                  />
                  <p
                    className="kanit-Regular"
                    style={{
                      color:
                        location.pathname === "/cart" ? "#E80045" : "black",
                      fontWeight: language === "en" ? "" : "bold",
                      margin: 0,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {set_localize_content(header_transalation.cart, language)}
                  </p>
                </div>
              </Badge>
            </Stack>
          </BootstrapTooltip>
        ) : null}
      </div>

      {state.loader ? <LoadingComponent activity={state.loader} /> : null}
    </div>
  );
}
